import React, { Component } from 'react';
import AirNav from 'air-components/air-component-navbar';
import Footer from 'air-components/air-component-footer';
import MenuList from 'air-components/menuList';

const ContactMenuList = [
    {
        id: 1,
        title: 'SRE LEADERSHIP TEAM',
        icon: 'user',
        href: '#',
        itemList: [
            {
                id: 1,
                title: 'Candice Tang',
                name: 'Candice.Tang@nike.com',
                href: 'mailto:Candice.Tang@nike.com'
            },
            {
                id: 2,
                title: 'Keaton Raymond',
                name: 'Keaton.Raymond@nike.com',
                href: 'mailto:Keaton.Raymond@nike.com'
            },
            {
                id: 3,
                title: 'Eric Schubert',
                name: 'Eric.Schubert@nike.com',
                href: 'mailto:Eric.Schubert@nike.com'
            },
            {
                id: 4,
                title: 'Justin Simas',
                name: 'Justin.Simas@nike.com',
                href: 'mailto:Justin.Simas@nike.com'
            },
            {
                id: 5,
                title: 'Chinmay Parida',
                name: 'Chinmay.Parida@nike.com',
                href: 'mailto:Chinmay.Parida@nike.com'
            },
            {
                id: 6 ,
                title: 'Demond Jackson',
                name: 'Demond.Jackson@nike.com',
                href: 'mailto:Demond.Jackson@nike.com'
            }
        ]
    },
    {
        id: 2,
        title: 'Email Groups',
        icon: 'envelope',
        href: '#',
        itemList: [
            {
                id: 1,
                name: 'Lst-digital.core.sre',
                href: 'mailto:Lst-digital.core.sre@nike.com'
            },
        ]
    },
    {
        id: 3,
        title: 'Slack Channels',
        icon: 'comments',
        href: '#',
        itemList: [
            {
                id: 1,
                name: '#ask-sre',
                description: 'Channel to discuss Resilience engineering, Observability & all things SRE.',
                href: 'slack://channel?team=T0G3T5X2B&id=CLDUYJ3F1'
            },
            {
                id: 2,
                name: '#nde-core-sre',
                description: 'Core SRE slack channel.',
                href: 'slack://channel?team=T0G3T5X2B&id=C6QU78FPF'
            }
        ]
    },
];

class Contact extends Component {
    render() {
        return (
            <React.Fragment>
               <AirNav activeItemId='contact' />
               <main className='container'>
                  <br/>
                  <MenuList menuList={ ContactMenuList }/>
               </main>
               <Footer/>
            </React.Fragment>
        );
    }
}

export default Contact;
