import React, { Component } from "react";

class SubMenuItem extends Component {
    render() {
        const { subMenuItem } = this.props;
        return (
            <React.Fragment>
                <ul className={ "no-style" }>
                        <li key={ subMenuItem.id }>
                            { 'title' in subMenuItem ?
                                <div>
                                    <h5 className={"nike-text"}>{ subMenuItem.title.toUpperCase() }</h5>
                                    <a className={ "navbar-links" } href={ subMenuItem.href } key={ subMenuItem.id }>
                                        { subMenuItem.name }
                                        { 'description' in subMenuItem ? <>: { subMenuItem.description }</>:<></>  }
                                    </a>
                                    <br/>
                                    <br/>
                                </div>
                                :<a className={ "navbar-links" } href={ subMenuItem.href } key={ subMenuItem.id }>
                                    { subMenuItem.name }
                                    { 'description' in subMenuItem ? <>: { subMenuItem.description }</>:<></>  }
                                </a>  }
                        </li>
                </ul>
            </React.Fragment>
        );
    }
}

export default SubMenuItem;
