import React, { Component } from "react";
import SpotlightTile from "./spotlightTile";

class SpotlightLayout extends Component {
    render() {
        const { tileList, tileLayout, title, colorScheme } = this.props;

        const tileCount = tileList.length;
        const colSizing = {
           lg: Math.floor(12/tileCount),
           md: Math.floor((12*2)/tileCount),
           sm: Math.floor((12*2)/tileCount),
        }

        return (
            <React.Fragment>
               <div className={"section-container col-sm-12"}>
                  <h1 className={ "nike-text no-margin" }> { title.toUpperCase() } </h1>
               </div>
               {tileList && tileList.map(tileItem =>
                  <SpotlightTile tile={tileItem} key={tileItem.id} layout={tileLayout} colorScheme={colorScheme} colSizing={colSizing}/>
               )}
            </React.Fragment>
        );
    }
}

export default SpotlightLayout;
