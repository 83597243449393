import React, { Component } from "react";
import PropTypes from "prop-types";
import { LinkItem } from ".";

export default class MenuItem extends Component {
  getStyles(navItemId, activeItemId, isActive) {
    let className = "nav-link";
    if ((navItemId === activeItemId && navItemId !== undefined) || isActive) {
      className += " active";
    }
    return className;
  }

  render() {
    const {
      children,
      title,
      activeItemId,
      navItemId,
      navData,
      isActive
    } = this.props;

    return (
      <div className="dropdown" id="air-dropdown">
        <a
          href={navData.href}
          className={this.getStyles(navItemId, activeItemId, isActive)}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {title}
        </a>
        <div className="dropdown-menu mega-dropdown">
          <div className="container">
            <div className="row">
              <div className="col-md-3 offset-md-1 dropdown-border-right">
                <div className="text-wrap">
                  <h5>{navData.title}</h5>
                  <p className="dropdown--text">{navData.leftText}</p>
                </div>
              </div>
              <div className="col-md-4 text-center align-self-center">
                {navData.children.map(item => {
                  if (!item.children) {
                    return item.type === "LinkItem" ? (
                      <LinkItem
                        key={item.id}
                        navItemId={item.id}
                        title={item.title}
                        href={item.href}
                      />
                    ) : (
                      ""
                    );
                  } else if (!item.children.children) {
                    return item.type === "LinkItem" ? (
                      <LinkItem
                        key={item.id}
                        navItemId={item.id}
                        title={item.title}
                        href={item.href}
                      />
                    ) : (
                      ""
                    );
                  }
                })}
              </div>
              <div className="col-md-3 dropdown-border-left">
                <div className="text-wrap">
                  <h5>TIPS</h5>
                  <p className="dropdown--text">{navData.rightText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MenuItem.propTypes = {
  isActive: PropTypes.string,
  navData: PropTypes.object,
  activeItemId: PropTypes.string,
  navItemId: PropTypes.string,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};
