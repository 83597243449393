import React from 'react'

import Dashboard from 'components/Dashboard';
import Contact from 'air-containers/contact';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';

import 'air-components/air-component-navbar/src/NavBar.css';
import 'air-components/air-component-footer/src/Footer.css';
import "bootstrap/dist/css/bootstrap.css";

import { OKTA_ISSUER, OKTA_CLIENT_ID } from 'config/okta.js';

const App = () => {

   return (
      <Router>
         <Security
            issuer={OKTA_ISSUER}
            client_id={OKTA_CLIENT_ID}
            redirect_uri={`${window.location.origin}/oAuthCallback`}
         >
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/contact' component={Contact} />
            <Route path="/oAuthCallback" component={ImplicitCallback} />
         </Security>
      </Router>
   );
}

export default App;
