import React, { Component } from "react";
import PropTypes from "prop-types";
import { LoaderItem } from ".";

export default class AirNav extends Component {
  render() {
    const { activeItemId, localHref, children } = this.props;

    return (
      <React.Fragment>
         <div
            id="nav-bar"
            className="navbar navbar-expand navbar-light bd-navbar bg-faded"
            role="navigation"
            aria-label="Main"
         >
            <ul className="nav navbar-nav text-nowrap mx-auto" id="list-nav">
               <li id="swoosh-icon">
                  <a className="navbar-brand" href="/">
                     <span className="g72-swoosh" id="custom-swoosh" />
                  </a>
               </li>
               <LoaderItem
                  activeItemId={activeItemId}
                  href={window.location.hostname === "localhost" && localHref ? localHref : "/assets/data/airnav.json"}
               />
            </ul>
            <br />
         </div>
         <div>{children}</div>
      </React.Fragment>
    );
  }
}

AirNav.propTypes = {
  activeItemId: PropTypes.string.isRequired,
  localHref: PropTypes.string,
  children: PropTypes.element
};
