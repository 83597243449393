import React, { Component } from "react";
import SubMenuItem from "./subMenuItem";

class SubMenuList extends Component {
    render() {
        const { subMenuList } = this.props;
        return (
            <React.Fragment>
                <ul className={ "no-style" }>
                    { subMenuList.map( subMenuItem =>
                        <SubMenuItem subMenuItem={ subMenuItem } key={ subMenuItem.id }/>
                    )}
                </ul>
            </React.Fragment>
        );
    }
}

export default SubMenuList;
