import React, { Component } from "react";
import MenuItem from "./menuItem";

class MenuList extends Component {
    render() {
        const { menuList } = this.props;
        return (
            <React.Fragment>
                { menuList.map( menuItem =>
                    <MenuItem menuItem={ menuItem } key={ menuItem.id }/>
                )}
            </React.Fragment>
        );
    }
}

export default MenuList;
