import React, { Component } from "react";
import PropTypes from "prop-types";

export default class AppNav extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className="appnav">
        <ul>{children}</ul>
      </div>
    );
  }
}

AppNav.propTypes = {
  children: PropTypes.element.isRequired
};
