import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            footerItems: [
                {
                    id: "contact",
                    value: "Contact Us",
                    description:
                        "Get in touch with our team or join our Slack channels to ask questions and receive " +
                        "notifications about new features.",
                    iconName: "headset",
                    href: "/contact/",
                    target: ""
                },
                {
                    id: "docs",
                    value: "Documentation",
                    description:
                        "Visit our Confluence page to learn more about our platform at sre.nike.com",
                    iconName: "book",
                    href: "https://confluence.nike.com/display/NDEPS/Core+SRE",
                    target: "_blank"
                }
            ]
        };
    }

    static getFooterClasses(footerItemId) {
        return(footerItemId === "contact")
            ? "nav navbar-nav flex-row"
            : "nav navbar-nav ml-md-auto d-node d-md-flex";
    }

    static getFooterBottomClass(fixedFooter){
        return (fixedFooter)
            ? "navbar navbar-expand navbar-dark bd-navbar bg-black fixed-bottom"
            : "navbar navbar-expand navbar-dark bd-navbar bg-black";
    }

    render() {
        const { fixedFooter } = this.props;
        return (
            <footer id="footer-nav-bar" className={Footer.getFooterBottomClass(fixedFooter)}>
                <div className="container">
                    {this.state.footerItems.map(footerItem => (
                        <ul className={Footer.getFooterClasses(footerItem.id)} key={footerItem.id}>
                            <li>
                                <a className="nav-link" href={footerItem.href} target={ footerItem.target }>
                                    <h3 className={ "footer-text" }>
                                        <FontAwesomeIcon icon={footerItem.iconName} />
                                        &nbsp;{footerItem.value.toUpperCase()}
                                    </h3>
                                    <p>{footerItem.description}</p>
                                </a>
                            </li>
                        </ul>
                    ))}
                </div>
            </footer>
        );
    }
}

export default Footer;
