import React, { Component } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SubMenuList from "./subMenuList";

class MenuItem extends Component {
  render() {
    const { menuItem } = this.props;
    return (
        <React.Fragment>
            <div key={ menuItem.id }>
                <a className={ "navtitle-links" } href={ menuItem.href }>
                    <h3 className={ "nike-text" } >
                      { 'icon' in menuItem ?
                          <FontAwesomeIcon icon={ menuItem.icon } /> : <></> }
                      { 'imageLink' in menuItem ?
                          <img className={ "icon-thumbnail" } src={ menuItem.imageLink } alt={ menuItem.title }/> :
                          <></> }
                      &nbsp;{ menuItem.title.toUpperCase() }
                    </h3>
                </a>
                <a className={ "navbar-links" } href={ menuItem.href }>
                    <div className={ "border-top col-12" }><br/></div>
                    { 'description' in menuItem ? <p>{ menuItem.description }</p> : <></> }

                </a>
                { 'itemList' in menuItem  ? <SubMenuList subMenuList={ menuItem.itemList } /> : <></> }
                <br/>
            </div>
        </React.Fragment>
    );
  }
}

export default MenuItem;
