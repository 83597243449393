import React, { Component } from "react";
import PropTypes from "prop-types";

export default class LinkItem extends Component {
  getStyles(navItemId, activeItemId, isActive) {
    let className = "nav-link";
    if ((navItemId === activeItemId && navItemId !== undefined) || isActive) {
      className += " active";
    }
    return className;
  }

  render() {
    const { href, title, activeItemId, navItemId, isActive } = this.props;

    return (
      <a
        className={this.getStyles(navItemId, activeItemId, isActive)}
        href={href}
        aria-label={title}
      >
        {title}
      </a>
    );
  }
}

LinkItem.propTypes = {
  isActive: PropTypes.bool,
  activeItemId: PropTypes.string,
  navItemId: PropTypes.string,
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};
