import React, { Component } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class SpotlightTile extends Component {
    render() {
        const { tile, colSizing } = this.props;
        const { lg, md, sm } = colSizing;

        return (
            <React.Fragment>
               <div className={`col-lg-${lg} col-md-${md} col-sm-${sm} no-padding`}>
                  <a href={ tile.href } aria-label={ tile.title } target={ tile.target }>
                     <LazyLoadImage
                        key={ "img-tiles-"+ tile.id }
                        alt={ tile.title }
                        effect="blur"
                        src={ tile.imageLink }/>
                  </a>
                  <div className="responsive-display-2-1 text-color-primary top-left"
                     style={{ color: "#ffffff"}}>
                     <h1>{ tile.title }</h1>
                     <a href={ tile.href } aria-label={ tile.title } target={ tile.target }
                     className="ncss-btn-primary-light navtitle-links" tabIndex="0">
                        GO
                     </a>
                  </div>
               </div>
            </React.Fragment>
        );
    }
}

export default SpotlightTile;
