import React from 'react';

import { useAuth } from 'utils/auth.js';
import { withAuth, authLogic } from '@okta/okta-react';

import Header from 'components/Header';
import Home from 'air-containers/home';

const Dashboard = withAuth(({auth}) => {

   const [authenticated, user] = useAuth(auth);

   console.log('authenticated = ', authenticated, 'user = ', user);

   if (authenticated !== null && !authenticated) {
      auth.login('/');
   }

   const notAuthenticatedElement = authenticated === null ? <div>Not loaded</div> : <div>Don't have access</div>;

   const isSuccessfullyAuthenticated = authenticated !== null && authenticated;

   if (!isSuccessfullyAuthenticated) {
      return notAuthenticatedElement;
   }

   return (
      <>
         {/* <Header text='Core SRE Dashboard' /> */}
         <Home />
      </>
   )
})

export default Dashboard;
