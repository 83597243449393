import React, { Component } from "react";
import Tile from "./tile";

class TileLayout extends Component {
    render() {
        const { tileList, tileLayout, layoutType, title, scrollable, scrollHeight, colorScheme } = this.props;
        const scrollStyle = {
            height: scrollHeight ? scrollHeight : "1000px",
        };

        return (
            <React.Fragment>
                { title
                    ? <div className={ "row" }>
                        <h1 className={ "nike-text" }> { title.toUpperCase() } </h1>
                        <hr/>
                    </div>
                    : <></>
                }
                <div className={TileLayout.getLayoutClass(layoutType)}>
                    {tileList && tileList.map(tileItem =>
                        <Tile tile={tileItem} key={tileItem.id} layout={tileLayout} colorScheme={colorScheme}/>
                    )}</div>
            </React.Fragment>
        );
    }

    static getLayoutClass(layoutType){
        return layoutType === "grid" ? "row justify-content-center" : "tile-list";
    }
}

export default TileLayout;
