import React, { Component } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class Tile extends Component {
    render() {
        const { tile, layout, colorScheme } = this.props;
        return (
            <React.Fragment>
               <div className={"tile tile-news nike_news ng-scope "+ layout} key={ tile.id }>
                  <div className="featured-image" align="center">
                     <a href={ tile.href } className={ Tile.getLinkClass(colorScheme) }
                        aria-label={ tile.title } target={ tile.target }>
                        <div className="story_image ng-binding ng-scope">
                           <LazyLoadImage
                              key={ "img-tiles-"+ tile.id }
                              alt={ tile.title }
                              effect="blur"
                              src={ tile.imageLink }/>
                        </div>
                     </a>
                  </div>
                  <div className="copy-wrapper">
                     <h3 className="story-title">
                        <a href={ tile.href } className={ Tile.getLinkClass(colorScheme) }
                           aria-label={ tile.title } target={ tile.target }>
                           <h4 className={Tile.getTextClass(colorScheme)} >{ tile.title }</h4>
                        </a>
                     </h3>
                     <p className="featured-summary ng-binding ng-scope">{ tile.description }</p>
                  </div>
               </div>
            </React.Fragment>
        );
    }

    static getTextClass(colorScheme){
        return colorScheme === "light" ? "nike-text-light" : "nike-text";
    }

    static getLinkClass(colorScheme){
        return colorScheme === "light" ? "navtitle-links-light" : "navtitle-links";
    }
}

export default Tile;
