import React, { Component } from 'react';
import AirNav from 'air-components/air-component-navbar';
import Footer from 'air-components/air-component-footer';
import TileLayout from 'air-components/tileLayout';
import SpotlightLayout from 'air-components/spotlightLayout';

const getImagePath = imgName => `/assets/images/home/${imgName}-resize.jpg`;

const Apps = [
    {
        id: 1,
        title: 'Service Health Dashboard',
        description: '',
        imageLink: getImagePath('AJ1-85'),
        href: 'https://health-dashboard-dev.sre.nikecloud.com/',
        target: ''
    },
    {
        id: 2,
        title: 'FSM Status',
        description: '',
        imageLink: getImagePath('ts-af1-1'),
        href: 'https://fsm-progress-dashboard.nde-coresre-test.nikecloud.com/',
        target: ''
    },
    {
        id: 3,
        title: 'SSL Certificate Dashboard',
        description: '',
        imageLink: getImagePath('cpfm'),
        href: 'https://ssl-certificate-dashboard.nde-coresre-test.nikecloud.com/',
        target: ''
    },
    {
        id: 4,
        title: 'Resilience Dashboard',
        description: '',
        imageLink: getImagePath('Adapt_BB'),
        href: 'https://resilience-dashboard.sre.nikecloud.com/',
        target: ''
    }
];
//

const TeamsAndPrograms1 = [
    {
        id: 1,
        title: 'Observability COE',
        description: 'Initiative created to lead Transformational Observability Initiatives across all of Global Technology.',
        imageLink: getImagePath('NYC-HouseOfInnovation'),
        href: 'https://confluence.nike.com/spaces/viewspace.action?key=coe',
        target: ''
    },
    {
        id: 2,
        title: 'Resilience Engineering',
        description: 'Program that partners with Global Technology teams for the purpose of experimenting on a distributed system in order to'
                     + 'build confidence in the system’s capacity to withstand turbulent conditions in production.',
        imageLink: getImagePath('airmax-city'),
        href: 'https://confluence.nike.com/pages/viewpage.action?pageId=226232908',
        target: '_blank'
    },
    {
        id: 3,
        title: 'Core SRE',
        description: 'Site Reliability Engineering squad chartered with implementing SRE best practices and tools to ensure Operational Readiness'
                     + 'through Strategic Programs and Initiatives',
        imageLink: getImagePath('kd13'),
        href: 'https://confluence.nike.com/display/NDEPS/Core+SRE',
        target: '_blank'
    }
];

const TeamsAndPrograms2 = [
    {
        id: 1,
        title: 'Embedded SRE',
        description: 'Site Reliability Engineering teams that implement SRE best practices and tools in their respective product domains.',
        imageLink: getImagePath('allstar-jersey'),
        href: 'https://confluence.nike.com/display/CCB/Reliability+Engineering',
        target: '_blank'
    },
    {
        id: 2,
        title: 'SWAT SRE',
        description: 'Site Reliability Engineering squad focused on addressing Technology Issues and Consumer Friction resulting from organizational gaps.',
        imageLink: getImagePath('sacai-blazer-yellow'),
        href: 'https://confluence.nike.com/display/CCB/SWAT',
        target: '_blank'
    },
];

class Home extends Component {

    render() {
        return (
            <React.Fragment>
               <AirNav activeItemId='coresre' />
               <main className='container-fluid'>
                  <div className='row justify-content-center'>
                     <SpotlightLayout tileList={ Apps }
                        title='SRE Apps'
                     />
                  </div>
                  <div className='row'>
                     <div className={ 'col-lg-12 col-md-12 col-sm-12' } >
                        <div className={'section-container'}>
                           <TileLayout tileList={ TeamsAndPrograms1 }
                              layoutType= { 'grid' }
                              colorScheme={ 'dark' }
                              scrollable={ false }
                              title={ 'Teams & Programs' }
                              tileLayout={ 'col-lg-3 col-md-4 col-sm-12' } />
                           <TileLayout tileList={ TeamsAndPrograms2 }
                              layoutType= { 'grid' }
                              colorScheme={ 'dark' }
                              scrollable={ false }
                              tileLayout={ 'col-lg-3 col-md-4 col-sm-12' } />
                        </div>
                     </div>
                  </div>
                  {/* <div className={ 'col-lg-12 col-md-12 col-sm-12 air-bg-dark' }>
                        <div className={'section-container'}>
                     <TileLayout tileList={ Intake }
                        layoutType= { 'grid' }
                        colorScheme={ 'light' }
                        scrollable={ false }
                        title={ 'Intake' }x
                        tileLayout={ 'col-lg-3 col-md-4 col-sm-12' }/>
                        </div>
                        </div>
                        <div className={ 'col-lg-12 col-md-12 col-sm-12' }>
                        <div className={'section-container'}>
                     <TileLayout tileList={ Reporting }
                        layoutType= { 'grid' }
                        colorScheme={ 'dark' }
                        scrollable={ false }
                        title={ 'Reporting' }
                        tileLayout={ 'col-lg-3 col-md-4 col-sm-12' }/>
                        </div>
                        </div>
                        <div className={ 'col-lg-12 col-md-12 col-sm-12 air-bg-grey' }>
                        <div className={'section-container'}>
                     <TileLayout tileList={ Tools }
                        layoutType= { 'grid' }
                        colorScheme={ 'light' }
                        scrollable={ false }
                        title={ 'Tools' }
                        tileLayout={ 'col-lg-3 col-md-4 col-sm-12' }/>
                        </div>
                  </div> */}
               </main>
               <Footer/>
            </React.Fragment>
        );
    }
}

export default Home;
