import React, { Component } from "react";
import PropTypes from "prop-types";
import { MenuItem, LinkItem } from ".";

export default class LoaderItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      navItems: []
    };
  }

  componentDidMount() {
    fetch(this.props.href)
      .then(res => res.json())
      .then(result => {
        this.setState({
          isLoaded: true,
          navItems: result.links
        });
      });
  }

  render() {
    const { activeItemId } = this.props;
    return (
      <React.Fragment>
        {this.state.navItems.map(navItem =>
          navItem.type === "MenuItem" ? (
            <MenuItem
              navData={navItem}
              key={navItem.id}
              activeItemId={activeItemId}
              navItemId={navItem.id}
              title={navItem.title}
              href={navItem.href}
            />
          ) : (
            <LinkItem
              navData={this.state.navItems}
              key={navItem.id}
              activeItemId={activeItemId}
              navItemId={navItem.id}
              title={navItem.title}
              href={navItem.href}
            />
          )
        )}
      </React.Fragment>
    );
  }
}

LoaderItem.propTypes = {
  activeItemId: PropTypes.string,
  href: PropTypes.string.isRequired
};
